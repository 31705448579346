import React, { Suspense } from "react";
import "./App.scss";

import Toaster from "./components/Shared/widgets/Toasts/Toaster";
import Loading from "./components/Shared/widgets/Loadings/Loading";
import { Switch, Route } from "react-router-dom";

// import Panel from "./components/Panels/Panel";
// import Landing from "./components/Home/Landing";
const Panel = React.lazy(() => import("./components/Panels/Panel"));
const Landing = React.lazy(() => import("./components/Home/Landings/Landing"));
function App() {
  return (
    <>
      <Toaster>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route path="/panel">
              <Panel />
            </Route>

            <Route path="/">
              <Landing />
            </Route>
          </Switch>
        </Suspense>
      </Toaster>
    </>
  );
}

export default App;
