import { currentUserReducer } from "./currentUser/currentUserReducer";
import { TrainingProgramReducers } from "./trainers/trainingReducer";
import { NutritionProgramReducers } from "./nutirtions/nutritionReducer";

import { combineReducers } from "redux";

export const rootedReducers = combineReducers({
  user: currentUserReducer,
  trainings: TrainingProgramReducers,
  nutritions: NutritionProgramReducers
});
