import * as React from "react";
import { Toast } from "./Toast";
import { ToasterService } from "../../../../services/ToasterService";

export function Toaster(props) {
  const _srv = ToasterService.GetInstance();
  const [list, SetList] = React.useState([]);

  React.useEffect(() => {
    _srv.eventEmitter.on("refresh", (args) => {
      console.info(args);
      SetList([...args]);
    });
  }, []);
  return (
    <>
      <div className=" toaster-container">
        {list &&
          [...list].map((t, idx) => (
            <Toast
              key={"toast_" + idx}
              info={t}
              close={() => {
                t.show = false;
                SetList([...list]);

                setTimeout(() => {
                  _srv.remove(idx);
                  list.splice(idx, 1);
                  SetList([...list]);
                }, 300);
              }}
              body={t.message}
            />
          ))}
      </div>
      {props.children}
    </>
  );
}

export default Toaster;
