import { EventEmitter } from "events";
import { toast } from "react-toastify";
export class ToasterService {
  private static _instance = new ToasterService();
  public eventEmitter = new EventEmitter();
  public static GetInstance() {
    return this._instance;
  }
  private constructor() {}

  toastList: any[] = [];
  remove(idx) {
    this.toastList.splice(idx, 1);
  }

  success(message: string) {
    toast.success(`${message}`);
    // this.toastList.push({
    //   _id: 1,
    //   message: message,
    //   show: true,
    //   title: "success",
    //   type: "success",
    //   timeout: 4000,
    // });
    // this.eventEmitter.emit("refresh", this.toastList);
  }

  info(message: string) {
    toast.info(` ${message}`);
    // this.toastList.push({
    //   _id: 2,
    //   message: message,
    //   show: true,
    //   title: "info",
    //   type: "info",
    //   timeout: 3000,
    // });
    // this.eventEmitter.emit("refresh", this.toastList);
  }
  warn(message: string) {
    toast.warn(` ${message}`);
    // this.toastList.push({
    //   _id: 2,
    //   message: message,
    //   show: true,
    //   title: "warning",
    //   type: "warning",
    //   timeout: 5000,
    // });
    // this.eventEmitter.emit("refresh", this.toastList);
  }
  error(message: string) {
    toast.error(` ${message}`);
    // this.toastList.push({
    //   _id: 2,
    //   message: message,
    //   show: true,
    //   title: "error",
    //   type: "error",
    //   timeout: 5000,
    // });
    // this.eventEmitter.emit("refresh", this.toastList);
  }
}
