import * as React from "react";
import { FaDumbbell } from "react-icons/fa";

export function Toast(props) {
  const [show, SetShow] = React.useState(true);
  const [init, SetInit] = React.useState(false);
  React.useEffect(() => {
    setTimeout(() => {
      SetInit(true);
    }, 100);
    if (props.info.timeout) {
      setTimeout(() => {
        props.close();
      }, +props.info.timeout);
    }
  }, []);
  return (
    <>
      <div
        className={
          "toast fade shadow notif " +
          (init && props.info?.show ? " show " : "")
        }
        role="alert"
      >
        <div className={"toast-header " + props.info.type}>
          <FaDumbbell size={15} className="mr-2" />
          {/* <img
            src="assets/images/gym.png"
            height={20}
            className="rounded mr-2"
            alt="..."
          /> */}
          <strong className="mr-auto"> {props.info?.title} </strong>
          <small className="ml-2 text-muted">NOW</small>
          <button
            type="button"
            className="ml-2 mb-1 close"
            onClick={(ev) => {
              props.close();
            }}
          >
            <div aria-hidden="true">&times;</div>
          </button>
        </div>
        <div className={"toast-body " + props.info.type}>
          {" "}
          {props.info?.message}{" "}
        </div>
      </div>
    </>
  );
}
