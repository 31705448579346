import {
    ERROR_NUTRITION_PROGRAM, FETCH_NUTRITION_PROGREAMS, SET_NUTRITION_PROGRAM
} from "./nutritionsTypes";

const initialState = {
    nutritionPrograms: [],
    selectedNutrition: null,
    loading: false,
    error: null,
};

export const NutritionProgramReducers = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_NUTRITION_PROGREAMS:
            return { ...state, loading: true };
        case SET_NUTRITION_PROGRAM:
            return { ...state, trainingsPrograms: action.payload, loading: false };
        case ERROR_NUTRITION_PROGRAM:
            return {
                ...state,
                trainingsPrograms: null,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};
