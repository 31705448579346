import React from "react";
import ReactDOM from "react-dom/client";

//import dontenv from "dotenv";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { store } from "./redux/store";

import storeInstance from "./mobX/NutritionItemMbx";
import { analytics, app } from "./firebase/Firebase";
import { configure } from "mobx";
//import { config } from "dotenv";
//dontenv.config();
//config();
export const StoreContext = React.createContext(storeInstance);
configure({ enforceActions: "always" });
console.info({ analytics, app });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <React.StrictMode>
  <Router>
    <ToastContainer
      position="top-right"
      autoClose={7000}
      hideProgressBar={false}
      newestOnTop={true}
      closeOnClick
      draggable
      pauseOnHover
    />
    <Provider store={store}>
      <StoreContext.Provider value={storeInstance}>
        <App />
      </StoreContext.Provider>
    </Provider>
  </Router>

  // </React.StrictMode>,
  // document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
