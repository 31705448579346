import { makeObservable, observable, autorun } from "mobx";
import _ from "underscore";

export class NutritionItemDayNutritionMealExtraStore {
  @observable title: any;
  @observable value: string = "";
  @observable id?: number = null;
  _uniqId: number = Math.ceil(Math.random() * 99999999);
  constructor() {
    makeObservable(this);
  }
}

export class NutritionItemDayNutritionMealStore {
  @observable title: string = "";
  @observable relatedImage: any;
  @observable weight: number = null;
  @observable id?: number = null;
  _uniqId: number = Math.ceil(Math.random() * 99999999);
  @observable extra: NutritionItemDayNutritionMealExtraStore[] = [];
  constructor() {
    makeObservable(this);
  }
  appendXtra() {
    this.extra.push(new NutritionItemDayNutritionMealExtraStore());
  }
}

export class NutritionItemDayNutritionStore {
  @observable category: string = "";
  @observable time: string = "";
  @observable relatedImage: any;
  @observable meals: NutritionItemDayNutritionMealStore[] = [];
  @observable id?: number = null;
  _uniqId: number = Math.ceil(Math.random() * 99999999);
  get report() {
    //console.log("Computing...");

    var extras = this.meals.map((m) => m.extra);
    var all = _.flatten(extras);
    var grouped = _.groupBy(all, (f) => f?.title?.title);
    //console.info(grouped);
    var keys = Object.keys(grouped);
    var er = {};
    keys.forEach((k) => {
      er[k] = _.reduce(grouped[k], (x, y) => x + +y.value, 0);
    });
    //return grouped;

    return er;
  }
  constructor() {
    makeObservable(this);
  }
  appendMeal(extras: any[]) {
    var m = new NutritionItemDayNutritionMealStore();
    extras.forEach((e) => {
      var eX = new NutritionItemDayNutritionMealExtraStore();
      eX.title = e;
      m.extra.push(eX);
    });
    this.meals.push(m);
  }
}

export class NutritionItemDayStore {
  @observable dayIndex: number;
  @observable weekIndex: number;
  @observable title: string = "";
  @observable id?: number = null;
  _uniqId: number = Math.ceil(Math.random() * 99999999);
  @observable nutritions: NutritionItemDayNutritionStore[] = [];
  constructor() {
    makeObservable(this);
  }

  get report() {
    var list = [];
    this.nutritions.forEach((n) => {
      var keys = Object.keys(n.report);
      keys.forEach((k) => {
        list.push({ title: k, cnt: n.report[k] });
      });
    });
    var grouped = _.groupBy(list, (f) => f.title);
    var keys = Object.keys(grouped);
    var er = {};
    keys.forEach((k) => {
      er[k] = _.reduce(grouped[k], (x, y) => x + +y.cnt, 0);
    });
    // console.info(er);
    return er;
  }
  appendNutrition() {
    this.nutritions.push(new NutritionItemDayNutritionStore());
  }
}

export class NutritionItemStore {
  @observable goal: string;
  @observable days: NutritionItemDayStore[] = [];
  _uniqId: number = Math.ceil(Math.random() * 99999999);
  constructor() {
    makeObservable(this);
  }

  appendDay(weekIndex, dayIndex) {
    var d = new NutritionItemDayStore();
    d.weekIndex = weekIndex;
    d.dayIndex = dayIndex;
    d.title = "";
    this.days.push(d);
  }
  removeDay(d: NutritionItemDayStore) {
    var idx = this.days.findIndex((f) => f._uniqId === d._uniqId);
    this.days.splice(idx, 1);
  }
  reload(a) {
    console.info("reloading...", a);
    this.goal = a.title;
    this.days = a.days.map((d) => {
      var dX = new NutritionItemDayStore();
      dX.weekIndex = d.weekIndex;
      dX.dayIndex = d.dayIndex;
      dX.id = d.id;
      dX.title = d.title;
      dX.nutritions = d.nutritions.map((n) => {
        var nX = new NutritionItemDayNutritionStore();
        nX.id = n.id;
        nX.category = n.category;
        nX.relatedImage = n.relatedImage;
        nX.time = n.time;
        nX.meals = n.meals.map((m) => {
          var mX = new NutritionItemDayNutritionMealStore();
          mX.id = m.id;
          mX.relatedImage = m.relatedImage;
          mX.title = m.title;
          mX.weight = m.weight;
          mX.extra = m.extra.map((e) => {
            var eX = new NutritionItemDayNutritionMealExtraStore();
            eX.id = e.id;
            eX.title = e.title;
            eX.value = e.value;
            return eX;
          });
          return mX;
        });
        return nX;
      });
      return dX;
    });
  }
}

const storeInstance = new NutritionItemStore();
export default storeInstance;

autorun(() => {
  console.info(storeInstance.goal);
});
