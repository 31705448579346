import { random } from "underscore";
import { LOGOUT, LOGIN, FETCH_CURRENT_USER, CACHE_TAG, NOTIFICATION_HIDE } from "./currentUserTypes";

const initialState = {
  auth: null,
  cacheTag: "",
  showNotification: true
};

export const currentUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT:
      return { ...state, auth: null };
    case NOTIFICATION_HIDE:
      return { ...state, showNotification: !state.showNotification };
    case LOGIN:
      return { ...state, auth: action.payload };
    case FETCH_CURRENT_USER:
      return { ...state };
    case CACHE_TAG:
      return { ...state, cacheTag: `${random(99999999999)}` };
    default:
      return state;
  }
};
