// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDzk0U264WBT1cmy0qlXJgWgD7JUFmAhTs",
    authDomain: "thrufitness-b86e9.firebaseapp.com",
    projectId: "thrufitness-b86e9",
    storageBucket: "thrufitness-b86e9.appspot.com",
    messagingSenderId: "1050202763723",
    appId: "1:1050202763723:web:3f256601e22f161750c64e",
    measurementId: "G-VLX02ET9LF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


export { app, analytics } 