import * as React from "react";
import "./Loading.scss";
export default function Loading({
  className = "",
  small = false,
  type = "circle",
}: {
  className?: string;
  small?: boolean;
  type?: "circle" | "grow" | "dot" | "dotted circle" | "linear dotted";
}) {
  return (
    <>
      {type === "grow" && (
        <div
          className={
            className +
            " spinner-grow text-danger " +
            (small ? " spinner-grow-sm " : "")
          }
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      )}
      {type === "circle" && (
        <div
          className={
            className +
            " spinner-border text-danger " +
            (small ? " spinner-border-sm " : "")
          }
        >
          <span className="sr-only">Loading...</span>
        </div>
      )}
      {type === "dot" && (
        <div className={"ticontainer " + className}>
          <div className="tiblock">
            <div className="tidot"></div>
            <div className="tidot"></div>
            <div className="tidot"></div>
          </div>
        </div>
      )}
      {type === "dotted circle" && (
        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
      {type === "linear dotted" && (
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
    </>
  );
}
